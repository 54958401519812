
import * as Sentry from '@sentry/vue'
import { Component, Vue } from 'vue-property-decorator'
import TeamNav from '@/components/team/nav.vue'
import DeleteTeam from '@/components/modals/confirmations/delete_team.vue'
import { TeamUpdateErrors } from '@/interfaces/responses'
import Team from '@/interfaces/team'
import TeamMember from '@/interfaces/team_member'
import TeamViewMixin from '@/mixins/team_view_mixin'
import Job from '@/interfaces/job'

interface LocalOverride {
  jobId: string
  position: number
}

@Component({
  components: {
    TeamNav,
  },
})
export default class TeamSettings extends TeamViewMixin {
  errors: TeamUpdateErrors = {}

  firstLoad = true

  jobsLoading = true

  solverDefault: string[] = []

  solverOverrides: LocalOverride[] = []

  team!: Team

  teamLeadId!: number

  teamLoading = true

  get inviteUrl(): string {
    return `${process.env.VUE_APP_URL}/team/join`
  }

  get jobs(): Job[] {
    return this.$store.state.jobs
  }

  get loading(): boolean {
    return this.teamLoading || this.jobsLoading
  }

  get realMembers(): TeamMember[] {
    // Return all Members of the Team that aren't Proxies, because Proxies cannot be made lead
    return this.team.members.filter((tm: TeamMember) => !tm.character.proxy)
  }

  get teamSolverSortOrder(): string[] {
    // Combine the default sort order and the overrides to generate the list of Job IDs on the table
    const table: string[] = []
    if (this.solverDefault.length === 0) return table

    for (let i = 0; i < this.solverDefault.length; i += 1) {
      table.push('')
    }

    // Loop through the overrides on the system, replace the empty strings
    this.solverOverrides.forEach(({ jobId, position }) => {
      table[position - 1] = jobId
    })

    // Loop through the defaults in order, if the job isn't in the overrides map then add it to the array
    const nonOverrides = this.solverDefault.filter((jobId: string) => !table.includes(jobId))
    nonOverrides.forEach((jobId: string) => {
      const replaceIndex = table.indexOf('')
      if (replaceIndex === -1) {
        throw new Error('somehow hit a -1 replace index')
      }
      table[replaceIndex] = jobId
    })
    return table
  }

  get url(): string {
    return `/backend/api/team/${this.teamId}/`
  }

  addOverride(): void {
    this.solverOverrides.push({ jobId: 'PLD', position: 1 })
    this.$forceUpdate()
  }

  checkPermissions(displayWarning: boolean): void {
    // Ensure that the person on this page is the team leader and not anybody else
    if (!this.userIsTeamLead) {
      this.$router.push(`/team/${this.$route.params.teamId}/`, () => {
        if (displayWarning) Vue.notify({ text: 'Only the team leader can edit a Team\'s settings.', type: 'is-warning' })
      })
    }
  }

  created(): void {
    this.fetchTeam(false)
    this.loadDefaultSortOrder()
  }

  disband(): void {
    this.$modal.show(DeleteTeam, { team: this.team })
  }

  async fetchTeam(reload: boolean): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.team = (await response.json()) as Team
        this.checkPermissions(this.firstLoad)
        this.teamLeadId = this.team.members.find((teamMember: TeamMember) => teamMember.character.user_id === this.$store.state.user.id)?.character.id ?? -1
        this.teamLoading = false
        this.firstLoad = false

        // Convert the team's solver sort overrides into the list version
        this.solverOverrides = []
        Object.entries(this.team.solver_sort_overrides).forEach(([jobId, position]) => {
          this.solverOverrides.push({ jobId, position })
        })
        this.solverOverrides.sort((overrideA, overrideB) => overrideA.position - overrideB.position)

        if (reload) this.$forceUpdate()
        document.title = `Settings - ${this.team.name} - Savage Aim`
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async load(): Promise<void> {
    this.fetchTeam(true)
  }

  async loadDefaultSortOrder(): Promise<void> {
    // Load the job sort data from the API
    try {
      const response = await fetch('/backend/api/job/solver/')
      if (response.ok) {
        // Parse the JSON into a team and save it
        const details = (await response.json()) as Job[]
        this.solverDefault = details.map((job: Job) => job.id)
      }
      else {
        this.$notify({ text: 'Could not load Jobs for Solver Order; help display will not work.', type: 'is-warning' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Jobs for Solver Order; help display will not work.`, type: 'is-warning' })
      Sentry.captureException(e)
    }
    finally {
      this.jobsLoading = false
    }
  }

  async regenerateInviteCode(): Promise<void> {
    try {
      const response = await fetch(this.url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        this.$notify({ text: 'New Invite Code Generated!', type: 'is-success' })
        await this.fetchTeam(true)
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to regenerate Team Invite Code.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  removeOverride(index: number): void {
    this.solverOverrides.splice(index, 1)
  }

  async saveDetails(): Promise<void> {
    this.errors = {}

    const sortOverrides: { [jobId: string]: number } = {}
    this.solverOverrides.forEach(({ jobId, position }) => {
      sortOverrides[jobId] = position
    })

    const updateObj = {
      name: this.team.name,
      tier_id: this.team.tier.id,
      team_lead: this.teamLeadId,
      solver_sort_overrides: sortOverrides,
    }

    try {
      const response = await fetch(this.url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body: JSON.stringify(updateObj),
      })

      if (response.ok) {
        this.$notify({ text: 'Successfully updated!', type: 'is-success' })
        await this.fetchTeam(true)
      }
      else {
        super.handleError(response.status)
        this.errors = (await response.json() as TeamUpdateErrors)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to update Team settings.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
