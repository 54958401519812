
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TeamBio from '@/components/team/bio.vue'
import Team from '@/interfaces/team'

@Component({
  components: {
    TeamBio,
  },
})
export default class DeleteTeam extends Vue {
  @Prop()
  team!: Team

  input = ''

  get canDelete(): boolean {
    return this.input === this.team.name
  }

  get url(): string {
    return `/backend/api/team/${this.team.id}/`
  }

  async deleteTeam(): Promise<void> {
    try {
      const response = await fetch(this.url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        // Attempt to parse the json, get the id, and then redirect
        this.$store.dispatch('fetchTeams')
        this.$emit('close')
        this.$router.push('/', () => {
          Vue.notify({ text: `${this.team.name} disbanded successfully!`, type: 'is-success' })
        })
      }
      else {
        this.$notify({ text: `Unexpected response ${response.status} when attempting to disband ${this.team.name}.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to disband ${this.team.name}.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
